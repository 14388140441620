export const MainConfig = {
    AppDetails: {
        app_name: "Taxi26000", 
        app_description: "Online Taxi Buchungssystem",
        app_identifier: "com.taxi26000.taxi26000",
        ios_app_version: "1.0.0", 
        android_app_version: 1
    },
    FirebaseConfig: {
    apiKey: "AIzaSyBnM19ljFMWxEl5evu1pO8wXV35IcfdqjI",
    authDomain: "taxi2600-moedling.firebaseapp.com",
    databaseURL: "https://taxi2600-moedling-default-rtdb.firebaseio.com",
    projectId: "taxi2600-moedling",
    storageBucket: "taxi2600-moedling.appspot.com",
    messagingSenderId: "794920401612",
    appId: "1:794920401612:web:62108302bd6fef16e82069",
    measurementId: "G-TFJ6V2VK4W"
    },
    Google_Map_Key: "AIzaSyDQ86HRRI_u2VTY83Swztm3oxycNm1vcwc",
    facebookAppId: "2869706306637315",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "",
        Buyer_Email_Address: ""
    }
}